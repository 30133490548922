.Shell-children {
  /* padding-top: 2rem; */
  min-width: 960px;
}

.userSettingsIcon path {
  stroke: #718096; 
}

.userSettingsIcon {
  width: 20px;
  height: 20px; 
}